import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

export const YesNoQuestion = (props: {
  processAnswer: (answer: string) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        outline={true}
        disabled={props.disabled}
        style={{ width: "100%", marginBottom: 10 }}
        onClick={() => props.processAnswer("yes")}
      >
        {t("common:yes")}
      </Button>
      <Button
        outline={true}
        disabled={props.disabled}
        style={{ minWidth: "100%" }}
        onClick={() => props.processAnswer("no")}
      >
        {t("common:no")}
      </Button>
    </>
  );
};
