import { Arke, IArke } from "@intreba/arke-api-client";
import { EnvironmentConfig } from "../environment";
import { ProfileStore } from "./ProfileStore";
import { VisitStore } from "./VisitStore";

export class RootStore {
  visitStore: VisitStore;
  profileStore: ProfileStore;

  constructor() {
    const arke: IArke = new Arke(EnvironmentConfig.arkeApi, async () => {
      console.error(
        "Tried refreshing JWT but visitor website does not support this."
      );
      return "";
    });

    this.profileStore = new ProfileStore();
    this.visitStore = new VisitStore(arke, this.profileStore);
  }
}
