import React, { useState } from "react";
import { Button, Form, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStores } from "../../hooks/useStores";

export const EnterName = () => {
  const { t } = useTranslation("nameSelect");
  let history = useHistory();
  const { profileStore } = useStores();
  const [userNameDetails, setUserNameDetails] = useState({
    firstName: "",
    lastName: "",
  });

  const onsubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    profileStore.setNameInformation({
      firstName: userNameDetails.firstName,
      lastName: userNameDetails.lastName,
    });

    history.push("/visits");
  };

  return (
    <>
      <Form onSubmit={(e) => onsubmit(e)}>
        <div style={{ marginBottom: 15 }}>
          <Label for={"firstName"}>{t("firstName")}</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            required={true}
            maxLength={150}
            onChange={(e) =>
              setUserNameDetails({
                ...userNameDetails,
                firstName: e.target.value,
              })
            }
            value={userNameDetails.firstName}
            disabled={false}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <Label for={"lastName"}>{t("lastName")}</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            required={true}
            maxLength={150}
            onChange={(e) =>
              setUserNameDetails({
                ...userNameDetails,
                lastName: e.target.value,
              })
            }
            value={userNameDetails.lastName}
            disabled={false}
          />
        </div>
        <div>
          <Button
            type="submit"
            disabled={
              userNameDetails.firstName.length < 1 ||
              userNameDetails.lastName.length < 1
            }
            style={{ width: "100%" }}
            color="primary"
          >
            {t("common:continue")}
          </Button>
        </div>
      </Form>
    </>
  );
};
