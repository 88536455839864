import { IArke } from "@intreba/arke-api-client/dist/types/Arke";
import {
  observable,
  action,
  computed,
  runInAction,
  makeObservable,
} from "mobx";
import { History } from "history";
import {
  PublicVisitResponseV1,
  VisitorRegistrationQuestionResponseV1,
  VisitorScreeningResponseV1,
  VisitStatus,
} from "@intreba/arke-api-client";
import { Name, ProfileStore } from "./ProfileStore";
export interface IStoredVisitInformation {
  visitId: string;
  siteId: string;
  jwtToken: string;
}
function isVisitResponse(object: any): object is PublicVisitResponseV1 {
  if (object === null) {
    return false;
  }
  return "site" in object;
}
export class VisitStore {
  constructor(private arke: IArke, private profileStore: ProfileStore) {
    makeObservable(this, {
      activeVisitStoredInfo: observable,
      visitSiteLogo: observable,
      visit: observable,
      screening: observable,
      processingAnswer: observable,
      currentScreeningQuestion: computed,
      processScreeningAnswer: action,
      SaveTokenAndMarkAsActiveVisit: action,
      RefreshActiveVisit: action,
      loadVisit: action,
      saveName: action,
    });

    this.RefreshActiveVisit();
  }

  activeVisitStoredInfo: IStoredVisitInformation | null = null;
  visitSiteLogo: string | null = null;
  visit: PublicVisitResponseV1 | null = null;
  screening: VisitorScreeningResponseV1 | null = null;
  processingAnswer: boolean = false;
  public get currentUrl() {
    if (this.visit === null || this.visit.visit === null) {
      return "/error";
    }
    const baseVisitUrl = `/visits/${this.visit.visit.visitId}`;
    if (this.visit.visit?.visitStatus === VisitStatus.ScreeningFailed) {
      return baseVisitUrl + "/screening-failed";
    } else if (
      this.visit.visit?.visitStatus === VisitStatus.AwaitingScreening
    ) {
      return this.screeningQuestionUrl;
    } else {
      return baseVisitUrl + "/view";
    }
  }
  public get qrImageSource(): string | undefined {
    if (
      this.visit === null ||
      this.visit.visit === null ||
      this.visit.visit.code === null
    ) {
      return undefined;
    }
    return this.arke.system.getQRUrl(this.visit.visit.code, 250);
  }
  public get screeningQuestionUrl() {
    if (this.visit === null || this.visit.visit === null) {
      return "/error";
    }
    const baseUrl = `/visits/${this.visit.visit.visitId}/`;
    const screenignQuestion = this.currentScreeningQuestion;
    if (screenignQuestion === null) {
      return "/error";
    } else if (screenignQuestion === "completed") {
      return baseUrl + "view";
    } else {
      return baseUrl + `screening/${screenignQuestion.additionalFieldId}`;
    }
  }
  public saveName(name: Name | null) {
    if (name === null || this.activeVisitStoredInfo === null) {
      return;
    }
    this.arke.visitorVisits.updateVisit(
      this.activeVisitStoredInfo?.siteId,
      this.activeVisitStoredInfo?.visitId,
      {
        visitorFirstName: name.firstName,
        visitorLastName: name.lastName,
      }
    );
  }
  public get currentScreeningQuestion():
    | VisitorRegistrationQuestionResponseV1
    | null
    | "completed" {
    if (this.screening === null) {
      return null;
    }
    const filteredQuestions = this.screening.questions
      .filter((a) => !a.isComplete)
      .sort((a, b) => a.order - b.order);
    if (filteredQuestions.length > 0) {
      return filteredQuestions[0];
    } else {
      return "completed";
    }
  }
  async loadVisit(history: History<unknown>) {
    if (this.activeVisitStoredInfo === null) {
      return;
    }
    console.log(this.activeVisitStoredInfo.visitId);
    const visit = await this.arke.visitorVisits.getVisit(
      this.activeVisitStoredInfo?.siteId,
      this.activeVisitStoredInfo?.visitId
    );
    const screening = await this.arke.visitorVisits.getScreening(
      this.activeVisitStoredInfo?.siteId,
      this.activeVisitStoredInfo?.visitId
    );
    if (isVisitResponse(visit) && visit.visit !== null && screening) {
      runInAction(() => {
        this.visit = visit as PublicVisitResponseV1;
        this.screening = screening as VisitorScreeningResponseV1;
      });
      if (
        this.profileStore.privacyPolicyAccepted &&
        this.profileStore.nameEntered
      ) {
        history.push(this.currentUrl);
      }
    } else {
      console.debug("Visit responses were not of expected type");
      history.push("/error");
    }
  }
  async processScreeningAnswer(
    visitId: string,
    additionalFieldId: string,
    answer: string,
    history: History<unknown>
  ) {
    this.processingAnswer = true;
    if (this.activeVisitStoredInfo === null) {
      return;
    }
    await this.arke.visitorVisits.setAnswer(
      this.activeVisitStoredInfo?.siteId,
      this.activeVisitStoredInfo?.visitId,
      {
        additionalFieldId: additionalFieldId,
        answer: answer,
      }
    );
    await this.loadVisit(history);
    runInAction(() => {
      this.processingAnswer = false;
      console.log("Set processing to false");
    });
  }
  private GetStoredVisitInfo(visitId: string): IStoredVisitInformation | null {
    const rawToken = localStorage.getItem(`storedvisit.${visitId}`);
    if (rawToken === null) {
      return null;
    }
    return JSON.parse(rawToken);
  }

  SaveTokenAndMarkAsActiveVisit(
    token: string,
    visitId: string,
    siteId: string
  ) {
    localStorage.setItem(
      `storedvisit.${visitId}`,
      JSON.stringify({ jwtToken: token, siteId: siteId, visitId: visitId })
    );
    localStorage.setItem("activeVisitId", visitId);
    this.RefreshActiveVisit();
  }

  RefreshActiveVisit() {
    const activeVisit = localStorage.getItem("activeVisitId");
    if (activeVisit === null) {
      this.activeVisitStoredInfo = null;
      return null;
    }

    this.activeVisitStoredInfo = this.GetStoredVisitInfo(activeVisit);
    if (this.activeVisitStoredInfo !== null) {
      this.visitSiteLogo = this.arke.sites.getLogoSource(
        this.activeVisitStoredInfo.siteId,
        "site-logo"
      );
      this.arke.setAccessToken(this.activeVisitStoredInfo.jwtToken);
    }
  }
}
