import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react-lite";
import { Spinner } from "../../components/spinner/Spinner";
import { AdditionalFieldType } from "@intreba/arke-api-client";
import { Label } from "reactstrap";
import { YesNoQuestion } from "./YesNoQuestion";
import { useTranslation } from "react-i18next";
export const Screening = observer(
  (
    props: RouteComponentProps<{ visitId: string; additionalFieldId: string }>
  ) => {
    const { visitStore } = useStores();
    const history = useHistory();
    const { t } = useTranslation("screening");

    if (
      visitStore.currentScreeningQuestion === null ||
      visitStore.currentScreeningQuestion === "completed"
    ) {
      return null;
    }
    if (
      visitStore.currentScreeningQuestion.additionalFieldType !==
      AdditionalFieldType.YesNo
    ) {
      return null;
    }
    return (
      <>
        <p
          dangerouslySetInnerHTML={{
            __html: t("intro")
              .replace(
                "{SiteName}",
                visitStore.visit?.site.name ?? t("common:unknown")
              )
              .replace(
                "{HostName}",
                visitStore.visit?.host?.name ?? t("common:unknown")
              ),
          }}
        ></p>
        <hr />
        <Label style={{ width: "100%", textAlign: "center" }}>
          {visitStore.currentScreeningQuestion.description}
        </Label>
        <YesNoQuestion
          disabled={visitStore.processingAnswer}
          processAnswer={(answer) =>
            visitStore.processScreeningAnswer(
              props.match.params.visitId,
              props.match.params.additionalFieldId,
              answer,
              history
            )
          }
        />
        {visitStore.processingAnswer && <Spinner />}
      </>
    );
  }
);
