export interface IEnvironmentConfig {
  arkeApi: string;
}

const DevelopmentConfig = {
  arkeApi: "http://localhost:49315",
};

const StagingConfig = {
  arkeApi: "https://api.staging.arke.io",
};

const ProductionConfig = {
  arkeApi: "https://api.arke.io",
};

let config = null;
switch (window.ArkeEnvironment) {
  case "Development":
    config = DevelopmentConfig;
    break;
  case "Staging":
    config = StagingConfig;
    break;
  case "Production":
    config = ProductionConfig;
    break;
}

const typedConfig: IEnvironmentConfig = config;

export { typedConfig as EnvironmentConfig };
