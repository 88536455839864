import { AccessCodeGenerationModes } from "@intreba/arke-api-client";
import React from "react";
import { useStores } from "../../../hooks/useStores";

export const VisitEntryCode = (props: {
  mode: AccessCodeGenerationModes;
  code: string;
}) => {
  const { visitStore } = useStores();
  if (props.mode === AccessCodeGenerationModes.QR) {
    return (
      <div className="pt-4 pl-2 pr-2 pb-4">
        <div className="qr text-center w-75 m-auto">
          <img
            className="img-fluid"
            src={visitStore.qrImageSource}
            alt="Entry QR code"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-2">
          <img src="/images/hash.svg" alt="Hash icon" />
        </div>
        <div className="col-10 font-weight-bold">
          <code>{props.code}</code>
        </div>
      </div>
    );
  }
};
