import { VisitData } from "@intreba/arke-api-client";
import React from "react";
import { useTranslation } from "react-i18next";

export const TimeRow = (props: { visitData: VisitData }) => {
  const { t } = useTranslation("visit");
  return (
    <div className="row">
      <div className="col-2">
        <img src="/images/calendar.svg" alt="Calendar icon" />
      </div>
      <div className="col-10">
        <div className="row">
          <div className="col-12">
            {new Date(props.visitData.from).toLocaleString(undefined, {
              weekday: "short",
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <small>
              {t("validUntil")}{" "}
              {new Date(props.visitData.until).toLocaleString(undefined, {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};
