import React from "react";
import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
export const ScreeningFailed = () => {
  const { t } = useTranslation("screeningResultScreen");
  console.log("Screening failed render");
  const { visitStore } = useStores();
  return (
    <div>
      {t("notAllowed").replace("{site}", visitStore.visit?.site.name ?? "")}
    </div>
  );
};
