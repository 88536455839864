import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useStores } from "../../hooks/useStores";

export const PrivacyPolicyAgreement = () => {
  const { t } = useTranslation("privacyPolicy");
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);
  const { profileStore } = useStores();
  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          profileStore.acceptPolicy();
          history.push("/profile/enter-name");
        }}
      >
        <div>{t("beforeContinue")}</div>
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <FormGroup style={{ marginTop: "1rem" }} check={true}>
            <Label check={true}>
              <Input
                required={true}
                checked={accepted}
                name="iAdhere"
                type="checkbox"
                onChange={(e) => setAccepted(e.target.checked)}
              />
              <span>
                {t("iAdhere")}
                <a href="https://arke.io/privacy">{t("linkText")}</a>
              </span>
            </Label>
          </FormGroup>
        </div>
        <div>
          <Button
            type="submit"
            style={{ width: "100%" }}
            color="primary"
            disabled={!accepted}
          >
            {t("common:continue")}
          </Button>
        </div>
      </Form>
    </>
  );
};
