import { observable, action, computed, makeObservable } from "mobx";
export interface Name {
  firstName: string;
  lastName: string;
}
export class ProfileStore {
  constructor() {
    makeObservable(this, {
      privacyPolicyAcceptedDate: observable,
      privacyPolicyAccepted: computed,
      acceptPolicy: action,
      setPolicyDate: action,
      name: observable,
      nameEntered: computed,
      setNameInformation: action,
      setName: action
    });

    const storedPolicyAcceptanceDate = localStorage.getItem("privacyPolicy");
    if (storedPolicyAcceptanceDate !== null) {
      this.setPolicyDate(new Date(storedPolicyAcceptanceDate));
    }

    const storedNameInformation = localStorage.getItem("name");
    if (storedNameInformation !== null) {
      this.setName(JSON.parse(storedNameInformation));
    }
  }
  public privacyPolicyAcceptedDate: Date | null = null;
  public get privacyPolicyAccepted() {
    return this.privacyPolicyAcceptedDate !== null;
  }

  acceptPolicy() {
    const acceptanceDate = new Date();
    localStorage.setItem("privacyPolicy", acceptanceDate.toISOString());
    this.setPolicyDate(acceptanceDate);
  }

  setPolicyDate(date: Date) {
    this.privacyPolicyAcceptedDate = date;
  }

  public name: Name | null = null;

  public get nameEntered() {
    return this.name !== null;
  }
  setNameInformation(name: Name) {
    localStorage.setItem("name", JSON.stringify(name));
    this.setName(name);
  }
  setName(name: Name) {
    this.name = name;
  }
}
