import * as React from "react";
import { useTranslation } from "react-i18next";

export function ErrorPage(props: { message?: string }) {
  const { t } = useTranslation();
  return (
    <div className="text-danger text-center">
      {props.message !== undefined ? props.message : t("common:error")}
    </div>
  );
}
