import React, { useEffect } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from "react-router-dom";
import { useStores } from "../../hooks/useStores";

import { Spinner } from "../../components/spinner/Spinner";
import { Screening } from "../screening/Screening";
import { ScreeningFailed } from "../screening/ScreeningFailed";
import { ViewVisit } from "./ViewVisit/ViewVisit";
import { observer } from "mobx-react-lite";
export const VisitRouter = observer(
  (props: RouteComponentProps<{ visitId: string }>) => {
    const { visitStore, profileStore } = useStores();
    const history = useHistory();
    useEffect(() => {
      visitStore.loadVisit(history);
      visitStore.saveName(profileStore.name);
    }, []);
    return (
      <Switch>
        <Route path="/visits" component={Spinner} exact={true}></Route>
        <Route path="/visits/:visitId" component={Spinner} exact={true}></Route>
        <Route path="/visits/:visitId/view" component={ViewVisit}></Route>
        <Route
          path="/visits/:visitId/screening/:additionalFieldId"
          component={Screening}
        ></Route>
        <Route
          path="/visits/:visitId/screening-failed"
          component={ScreeningFailed}
        ></Route>
      </Switch>
    );
  }
);
