import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { useStores } from "../../hooks/useStores";

export const Header = observer(() => {
  const { visitStore, profileStore } = useStores();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <header>
      <div>
        {visitStore.visitSiteLogo !== null && (
          <img
            src={visitStore.visitSiteLogo}
            alt="Site logo"
            style={{ width: "76%", marginLeft: "12%" }}
          />
        )}

        <h3>
          {t("common:welcomeHeader")}
          {profileStore.nameEntered && (
            <span>
              &nbsp;{profileStore.name?.firstName} {profileStore.name?.lastName}
            </span>
          )}
          ,
          {profileStore.nameEntered && (
            <Button
              color="link"
              style={{ fontSize: 12, verticalAlign: "top" }}
              onClick={(e) => {
                e.preventDefault();
                history.push("/profile/enter-name");
              }}
            >
              {t("common:notYou")}
            </Button>
          )}
        </h3>
      </div>
    </header>
  );
});
