import * as React from "react";
import "./Spinner.scss";

interface ISpinnerProps {
  skipTheme?: boolean;
  inline?: boolean;
  className?: string;
}

export function Spinner(props: ISpinnerProps) {
  let classNames = props.skipTheme === true ? "spinner" : "spinner themed";

  if (props.className !== undefined) {
    classNames = classNames + " " + props.className;
  }
  return (
    <div
      className={classNames}
      style={{
        display: props.inline === true ? "inline-block" : undefined,
      }}
    >
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
}
