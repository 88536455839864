import {
  AccessCodeGenerationModes,
  PublicVisitResponseV1,
  VisitData,
  VisitStatus,
} from "@intreba/arke-api-client";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import { useStores } from "../../../hooks/useStores";
import { ErrorPage } from "../../shared/ErrorPage";
import { VisitEntryCode } from "./VisitEntryCode";
import { TimeRow } from "./TimeRow";

const GetTimeDifference = (dateStart: Date, dateEnd: Date) => {
  const difference = dateEnd.getTime() - dateStart.getTime();
  const differenceMinutes = difference / (60 * 1000);
  try {
    const timeFormatter = new Intl.RelativeTimeFormat();
    return timeFormatter.format(Math.round(difference / (60 * 1000)), "minute");
  } catch {
    return `${differenceMinutes} minutes`;
  }
};
const ViewVisitContent = observer((props: { visit: PublicVisitResponseV1 }) => {
  const { t } = useTranslation("visit");
  if (props.visit.visit === null || props.visit.site === null) {
    return <ErrorPage />;
  }
  const now = new Date();
  const visitFrom = new Date(props.visit.visit.from);

  return (
    <>
      {visitFrom > now && (
        <Alert color="warning">
          <span
            dangerouslySetInnerHTML={{
              __html: t("visitActiveIn").replace(
                "{TimeDifference}",
                GetTimeDifference(now, visitFrom)
              ),
            }}
          ></span>
        </Alert>
      )}
      <span
        dangerouslySetInnerHTML={{
          __html: t(
            props.visit.visit?.codeMode === AccessCodeGenerationModes.QR
              ? "qrUsageIntro"
              : "pinUsageIntro"
          ).replace("{SiteName}", props.visit.site.name),
        }}
      />
      {props.visit.visit.code !== null && (
        <VisitEntryCode
          mode={props.visit.visit.codeMode}
          code={props.visit.visit.code}
        />
      )}

      <hr />
      <TimeRow visitData={props.visit.visit} />
    </>
  );
});

const VisitWaitText = (props: {
  visitData: VisitData;
  translationKey: string;
}) => {
  const { t } = useTranslation("visit");
  return (
    <div className="row">
      <div className="col-2">
        <img src="/images/clock.svg" alt="Hash icon" />
      </div>
      <div className="col-10">
        <span
          dangerouslySetInnerHTML={{
            __html: t(props.translationKey)
              .replace(
                "{From}",
                new Date(props.visitData.from).toLocaleString(undefined, {
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              )
              .replace(
                "{Until}",
                new Date(props.visitData.until).toLocaleString(undefined, {
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              )
              .replace(
                "{ScreeningOpenedFrom}",
                props.visitData.screeningOpenedFrom !== null
                  ? new Date(
                      props.visitData.screeningOpenedFrom
                    ).toLocaleString(undefined, {
                      weekday: "short",
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  : t("common:unknown")
              ),
          }}
        ></span>
      </div>
    </div>
  );
};
export const ViewVisit = observer(() => {
  const { t } = useTranslation("visit");
  const { visitStore } = useStores();
  if (visitStore.visit === null || visitStore.visit.visit === null) {
    return null;
  }
  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: t("welcome")
            .replace("{SiteName}", visitStore.visit.site.name)
            .replace(
              "{HostName}",
              visitStore.visit.host?.name ?? t("common:unknown")
            ),
        }}
      ></p>
      <hr />
      {visitStore.visit.visit.visitStatus === VisitStatus.Requested && (
        <VisitWaitText
          translationKey="waitRequested"
          visitData={visitStore.visit.visit}
        />
      )}
      {visitStore.visit.visit.visitStatus === VisitStatus.Scheduled &&
        visitStore.visit.visit.screeningOpenedFrom === null && (
          <VisitWaitText
            translationKey="scheduled"
            visitData={visitStore.visit.visit}
          />
        )}
      {visitStore.visit.visit.visitStatus === VisitStatus.Scheduled &&
        visitStore.visit.visit.screeningOpenedFrom !== null && (
          <VisitWaitText
            translationKey="scheduledScreening"
            visitData={visitStore.visit.visit}
          />
        )}
      {visitStore.visit.visit.visitStatus > 399 && <ErrorPage />}
      {visitStore.visit.visit.visitStatus > 9 &&
        visitStore.visit.visit.visitStatus < 400 && (
          <ViewVisitContent visit={visitStore.visit} />
        )}
    </div>
  );
});
