import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useStores } from "../hooks/useStores";
import { ErrorPage } from "./shared/ErrorPage";
import { Footer } from "./shared/Footer";
import { EnterName } from "./profile/EnterName";
import { PrivacyPolicyAgreement } from "./profile/PrivacyPolicyAgreement";
import { Header } from "./shared/Header";
import { VisitRouter } from "./visit/VisitRouter";
import { observer } from "mobx-react-lite";

interface VisitJwt {
  SiteId: string;
  VisitId: string;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
}

export const Router = observer(() => {
  let history = useHistory();
  const { visitStore, profileStore } = useStores();
  const [checkedUrl, setCheckedUrl] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let jwt: string | null = urlParams.get("jwt");
    if (jwt !== null) {
      const decodedJwt = jwt_decode(jwt) as VisitJwt;
      if (decodedJwt.VisitId !== undefined) {
        visitStore.SaveTokenAndMarkAsActiveVisit(
          jwt,
          decodedJwt.VisitId,
          decodedJwt.SiteId
        );
      }
    } else {
      console.debug("No new JWT found");
    }
    setCheckedUrl(true);
  }, [visitStore]);
  if (checkedUrl && visitStore.activeVisitStoredInfo === null) {
    return <ErrorPage />;
  }
  useEffect(() => {
    console.log("Running redirect effect");
    if (checkedUrl !== true) {
      console.log("Cancelling early");
      return;
    }
    console.log("Really executing it");
    //TODO see if we can fold into 1 effect
    if (visitStore.activeVisitStoredInfo !== null) {
      if (profileStore.privacyPolicyAccepted) {
        if (profileStore.nameEntered) {
          history.push(`/visits/${visitStore.activeVisitStoredInfo.visitId}`);
        } else {
          history.push(`/profile/enter-name`);
        }
      } else {
        history.push(`/profile/privacy-policy`);
      }
    } else {
      console.error("Active visit stored info doesn't exist");
      history.push("/error");
    }
  }, [checkedUrl, visitStore, profileStore, history]);

  return (
    <div className="app p-4">
      <div className="wrapper p-4">
        <Header />
        <Switch>
          <Route path="/error" component={ErrorPage}></Route>
          <Route path="/visits" component={VisitRouter}></Route>
          <Route path="/profile/enter-name" component={EnterName}></Route>
          <Route
            path="/profile/privacy-policy"
            component={PrivacyPolicyAgreement}
          ></Route>
        </Switch>
        <Footer />
      </div>
    </div>
  );
});
