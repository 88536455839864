import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { RootStore } from "./stores/RootStore";
import { StoreProvider } from "./hocs/useStore";
import i18n from "i18next";
import { Router } from "./pages/Router";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";

// don't allow state modifications outside actions
configure({
  enforceActions: "observed",
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: {
          error: "Something went wrong, please try again or contact your host",
          poweredBy: "Powered by Arke",
          unknown: "Unknown",
          yes: "Yes",
          no: "No",
          continue: "Continue",
          welcomeHeader: "Hi",
          notYou: "Not you?",
        },
        visit: {
          validUntil: "Valid until",
          welcome:
            "Welcome to <strong>{SiteName}</strong> for your visit with <strong>{HostName}</strong>",
          qrUsageIntro:
            "This is your entry-pass for {SiteName}. Please scan the QR code at a visitor entrance when you arrive.",
          pinUsageIntro:
            "This is your entry-pass for {SiteName}. Please use this code at a visitor entrance when you arrive.",
          waitRequested:
            "Your visit is still being proccessed, try again in a minute or two",
          scheduled:
            "Your visit is scheduled. Check back here at {From} to retrieve your access code.",
          scheduledScreening:
            "Your visit is scheduled. Check back here at {ScreeningOpenedFrom} to answer some questions the site would like to ask you and retrieve your access code.",
          visitActiveIn:
            "Please note your visit will only be active {TimeDifference}.",
        },
        nameSelect: {
          firstName: "First name",
          lastName: "Last name",
        },
        privacyPolicy: {
          linkText: "privacy policy",
          iAdhere: "By checking this box I adhere to the Arke ",
          beforeContinue:
            "Before we can continue, the site has setup a screening and would like to ask you some questions. Once the questions are answered satisfactory you will be given your code to access the site.",
        },
        screening: {
          intro:
            "{SiteName} would like to ask you some questions before your visit with {HostName}.",
        },
        screeningResultScreen: {
          notAllowed:
            "We're sorry to inform you that the policies of {site} do not allow you to visit. Please contact your host for alternative options.",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });
const store = new RootStore();
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <Router></Router>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
