import React from "react";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="row">
        <div className="col-12 text-center">
          <hr />
          {t("common:poweredBy")}{" "}
          <img
            src="/logo512.png"
            alt="User icon"
            className="img-fluid d-inline"
            style={{ width: "12%" }}
          />
        </div>
      </div>
    </footer>
  );
}
